<template>
  <div>
    <div style="margin: 20px 0 -20px 20px">
      <MyBreadcrumb></MyBreadcrumb>
    </div>
    <div class="feedback-modify">
      <div class="feedback-modify-main">
        <div class="left">
          <Spin fix v-if="infoLoading"></Spin>
          <div v-else>
            <div class="left-right">
              <div v-if="dataList.length">
                <div class="left-right-top">
                  <p>纠错区</p>
                  <p>纠错人:{{correctionData.user && correctionData.user.nickname}}({{correctionData.user && correctionData.user.id}})</p>
                </div>
                <div class="correction">
                  <div class="correction-top">
                    <div>
                      <span class="correct-tit">纠错标题: </span><span>{{correctionTypes[correctionData.err_type]}} </span>
                    </div>
                    <div>
                      {{ correctionData.created_at }}
                    </div>
                  </div>
                  <div class="correction-top">
                    <div class="correction-top-l">
                     <span class="correct-tit">纠错内容:</span>
                      <span>
                        <Richtxt :height="150" :isInline="true" :isDisabled="true" :dataObj="{val:correctionData && correctionData.err_message}" key="feedback"></Richtxt>
                      </span>
                    </div>
                  </div>
                  <div class="correction-type">
                    <span class="correct-tit">{{ $t('trainingcamp_train_correction_type') }}:</span>
                    <RadioGroup v-model="correctionData.type" size="large">
                      <Radio v-for="item in typesList" :disabled="true" :label="item.value" :key="item.value">{{item.name}}</Radio>
                    </RadioGroup>
                    <!--                  {{ correctionInfo && typesObj[correctionInfo.type]}}-->
                  </div>
                  <div class="correction-tit">
                    <div class="correction-left">
                      <p class="correction-data-tit">{{ $t('trainingcamp_train_correction_title') }}</p>
                      <Button type="primary" style="margin-top: 10px" @click="syncTit">同步</Button>
                    </div>
                    <div class="correction-right">
                      <Richtxt :height="150" :statusbar="true" :isDisabled="true" :dataObj="{val:correctionData && correctionData.name}" key="feedback"></Richtxt>
                    </div>
                  </div>
                  <div class="correction-option-item" v-for="(item,index) in correctionData.options" :key="index">
                    <div class="correction-left">
                      <p class="correction-data-tit">{{optionNameList[index]}}</p>
                      <p class="correction-correct" :class="item.is_correct == '1' ? 'active' : ''" v-if="item.is_correct == '1'" >{{item.is_correct == '1' ? $t('trainingcamp_train_correction_correct') : $t('trainingcamp_train_correction_set_correct')}}</p>
                      <Button type="primary" style="margin-top: 10px" @click="syncOption(index)">同步</Button>
                    </div>
                    <div class="correction-right">
                      <Richtxt :dataObj="{val:item.name}" :isDisabled="true" :height="150"></Richtxt>
                    </div>
                  </div>
                  <Button type="primary" @click="syncIsRight">同步正确答案</Button>
                  <div class="correction-analysis">
                    <div class="correction-left">
                      <p class="correction-data-tit">{{ $t('trainingcamp_train_correction_analysis') }}</p>
                      <Button type="primary" style="margin-top: 10px" @click="syncAnalysis">同步</Button>
                    </div>
                    <div class="correction-right">
                      <Richtxt :isDisabled="true" :statusbar="true" :height="150" :dataObj="{val:correctionData && correctionData.explain}" ></Richtxt>
                    </div>
                  </div>
                  <Button type="primary" @click="syncAll">同步所有</Button>
                </div>
              </div>
              <div v-else style="text-align: center">{{ $t('trainingcamp_exam_feedback_no_data') }}</div>
            </div>
            <div class="left-top" >
              <div v-if="dataList.length && correctionInfo">
                <div class="left-right-top">
                  <p>更正区</p>
                  <p>本题状态:{{dataStatusObj[correctionInfo.status]}}</p>
                </div>
                <div v-if="correctionInfo.id">
                  <EditData :allData="{data:correctionInfo,allCategory:allCategoryList}" :statusObj="statusObj" :levelObj="levelsObj" :typesList="typesList" :sourceFroms="sourceFroms" @changeData="changeData"></EditData>
                </div>

<!--                <div class="correction">-->
<!--                  <div class="correction-type">-->
<!--                    {{ $t('trainingcamp_train_correction_type') }}:-->
<!--                    <RadioGroup v-model="questionType" size="large">-->
<!--                      <Radio v-for="item in typesList" :label="item.name" :key="item.value"></Radio>-->
<!--                    </RadioGroup>-->
<!--                    &lt;!&ndash;                  {{ correctionInfo && typesObj[correctionInfo.type]}}&ndash;&gt;-->
<!--                  </div>-->
<!--                  <div class="correction-tit">-->
<!--                    <div class="correction-left">-->
<!--                      <p class="correction-data-tit">{{ $t('trainingcamp_train_correction_title') }}</p>-->
<!--                    </div>-->
<!--                    <div class="correction-right">-->
<!--                      <Richtxt :height="150" :statusbar="true" :dataObj="{val:correctionInfo && correctionInfo.name}" @saveData="(data)=>saveCurData(data,correctionInfo,'name')" key="feedback"></Richtxt>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="correction-option-item" v-for="(item,index) in correctionInfo.options" :key="item.id">-->
<!--                    <div class="correction-left">-->
<!--                      <p class="correction-data-tit">{{optionNameList[index]}}</p>-->
<!--                      <p class="correction-correct" :class="item.is_correct == '1' ? 'active' : ''" @click="changeCorrect(item)">{{item.is_correct == '1' ? $t('trainingcamp_train_correction_correct') : $t('trainingcamp_train_correction_set_correct')}}</p>-->
<!--                      <p class="correction-dele" v-if="correctionInfo.options.length > 1" @click="deleOption(index)">{{ $t('trainingcamp_train_correction_delete') }}</p>-->
<!--                    </div>-->
<!--                    <div class="correction-right">-->
<!--                      <Richtxt :dataObj="{val:item.name}" :height="150" @saveData="(data)=>saveCurData(data,item,'name')"></Richtxt>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <Button type="primary" @click="addOption">{{ $t('trainingcamp_train_correction_add_option') }}</Button>-->
<!--                  <div class="correction-analysis">-->
<!--                    <div class="correction-left">-->
<!--                      <p class="correction-data-tit">{{ $t('trainingcamp_train_correction_analysis') }}</p>-->
<!--                    </div>-->
<!--                    <div class="correction-right">-->
<!--                      <Richtxt :statusbar="true" @saveData="(data)=>saveCurData(data,correctionInfo,'explain')" :height="150" :dataObj="{val:correctionInfo && correctionInfo.explain}" ></Richtxt>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->

              </div>

              <div v-else style="text-align: center">{{ $t('trainingcamp_exam_feedback_no_data') }}</div>
            </div>
          </div>

          <div class="bot">
            <div class="btn">
<!--              <Button size="large" type="error" @click="showMessage(3,3)">下线此题</Button>-->
<!--              <Button size="large" type="info" @click="showMessage(2,4)">疑难题</Button>-->
<!--              <Button size="large" type="warning" @click="submit('',2)">无效纠错</Button>-->
              <!--        <Button type="error" @click="ignore" v-if="status == 0 && correctionData.status == '0'  || status != 0">{{ $t('trainingcamp_exam_correction_ignore') }}</Button>-->
              <Button size="large" type="success" @click="showMessage()">{{ $t('trainingcamp_exam_correction_save_submit') }}</Button>
              <Button size="large" @click="pre">{{$t('trainingcamp_train_pre')}}</Button>
              <Button size="large" @click="next">{{$t('trainingcamp_train_next')}}</Button>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="tab">
            <div class="tab-list">
              <div :class="status == item.id ? 'active' : ''" v-for="item in tabList" :key="item.id" @click="changeTab(item)">
                {{ item.name }}
              </div>
            </div>
          </div>

          <div class="search">
            <Input v-model="keyword"
                   :placeholder="$t('trainingcamp_exam_feedback_search')"
                   clearable
                   search
                   :enter-button="true"
                   @on-enter="getList"
                   @on-search="getList"
            />
          </div>
          <div class="catrgory-search">
            <div>
              <p>一级分类:</p>
              <Select v-model="categoryId" clearable style="margin-right: 10px" @on-change="(data)=>{changeCategory(data,'categoryId')}">
                <Option v-for="item in categoryList" :value="item.id" :key="item.id">{{ item.name }}</Option>
              </Select>
            </div>
            <div>
              <p>二级分类:</p>
              <Select v-model="secondCategoryId" clearable style="margin-right: 10px" @on-change="(data)=>{changeCategory(data,'secondCategoryId')}">
                <Option v-for="item in secondCategoryList" :value="item.id" :key="item.id">{{ item.name }}</Option>
              </Select>
            </div>
          </div>
          <div class="catrgory-search">
            <div>
              <p>三级分类:</p>
              <Select v-model="threeCategoryId" clearable @on-change="(data)=>{changeCategory(data,'threeCategoryId')}">
                <Option v-for="item in threeCategoryList" :value="item.id" :key="item.id">{{ item.name }}</Option>
              </Select>
            </div>
            <div>
              <p>批次:</p>
              <Select v-model="batchId" clearable @on-change="(data)=>{changeCategory(data,'batchId')}">
                <Option v-for="item in batchList" :value="item.batch" :key="item.batch">{{ item.batch }}</Option>
              </Select>
            </div>
          </div>
          <div class="catrgory-search">
            <div>
              <p>纠错类型:</p>
              <Select v-model="data_correction_type" clearable @on-change="(data)=>{changeCategory(data,'data_correction_type')}">
                <Option v-for="item in correctTypeList" :value="item.id" :key="item.id">{{ item.name }}</Option>
              </Select>
            </div>
            <div>
              <p>纠错来源:</p>
              <Select v-model="generate_by" clearable @on-change="(data)=>{changeCategory(data,'generate_by')}">
                <Option v-for="item in generate_by_list" :value="item.id" :key="item.id">{{ item.name }}</Option>
              </Select>
            </div>
          </div>
          <div class="right-top">
            <div class="tit">
              <div>
                {{ $t('trainingcamp_train_answer_sheet') }}
                <Tooltip>
                  <Icon type="ios-help-circle-outline" />
                  <div slot="content">
                    <p>{{ $t('trainingcamp_train_white') }}</p>
                    <p>{{ $t('trainingcamp_train_blue') }}</p>
                    <p>{{ $t('trainingcamp_train_green') }}</p>
                    <p>{{ $t('trainingcamp_train_red') }}</p>
                  </div>
                </Tooltip>
                <div class="count">
                  {{ $t('trainingcamp_exam_feedback_surplus') }}<span>{{ remainingTotal }}</span>{{ $t('trainingcamp_exam_feedback_topic') }}
                </div>
              </div>
              <Button type="text" @click="refresh">{{ $t('trainingcamp_exam_feedback_refresh') }}</Button>
            </div>

            <div class="answer-card">
              <Spin fix v-if="loading"></Spin>
              <div v-else>
                <div class="list" v-if="dataList.length">
                  <div :class="{active:dataList[curIndex].id == item.id,error:item.status == '2',corret:item.status == '1'} " v-for="(item,index) in dataList" :key="item.id" @click="changeAnswerCard(index)">{{index + 1}}</div>
                </div>
                <div v-else style="text-align: center">
                  {{ $t('trainingcamp_exam_feedback_no_data') }}
                </div>
                <div class="page">
                  <Page :total="remainingTotal" :current="page" :page-size="pageSize" @on-change="changePage"></Page>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
          v-model="messageModal"
          title="操作"
      >
        <Form ref="batchOffForm" :model="messageForm" :rules="ruleMessage" :label-width="80">
          <FormItem prop="message" label="原因:">
            <Input type="textarea" v-model="messageForm.message" placeholder="请输入原因">
            </Input>
          </FormItem>

        </Form>
        <div slot="footer">
          <Button @click="messageCancel">取消</Button>
          <Button type="primary" @click="messageConfirm">确定</Button>
        </div>
      </Modal>
      <!--    <DeleModal :status="deleModalStatus" :txt="$t('dataset_data_dele_tip')" :loading="modalLoading" @cancel="cancelDele" @confirm="confirmDele"></DeleModal>-->
    </div>
  </div>

</template>

<script>
// import DeleModal from '@/components/deleteModal.vue';
import Richtxt from '@/components/production/richtxt.vue';
import util from '@/utils/tools.js';
import EditData from './components/data/editData'

export default {
  name: "role",
  data(){
    return{
      page:1,
      pageSize:50,
      status:0,  //0待复核1通过2未通过的
      dataList:[],
      loading:true,
      allowLoading:false,
      notAllowLoading:false,
      typesObj:{},
      typesList:[],
      curIndex:0,
      optionList:['A','B','C','D','E','F'],
      correctionModal:false,
      optionNameList:[this.$t('trainingcamp_train_correction_option_a'),this.$t('trainingcamp_train_correction_option_b'),this.$t('trainingcamp_train_correction_option_c'),this.$t('trainingcamp_train_correction_option_d'),this.$t('trainingcamp_train_correction_option_e'),this.$t('trainingcamp_train_correction_option_f'),this.$t('trainingcamp_train_correction_option_g')],
      correctionInfo:{},
      feedbackModal:false,
      formItem:{
        title:'',
        feedback:'',
      },
      ruleValidate: {
        title:[{ required: true, message: this.$t('trainingcamp_train_feedback_tit_placeholder'), trigger: 'blur' }],
        feedback: [
          { required: true, message: this.$t('trainingcamp_train_feedback_cont_placeholder'), trigger: 'blur' }
        ]
      },
      radio:'',
      checkbox:[],
      correctIndex:'',
      correctAnswer:'',
      keyword:'',
      tabList:[],
      remainingTotal:0,
      infoLoading:false,
      deleModalStatus:false,
      modalLoading:false,
      total:0,
      questionType:'',
      correctionDataLoading:false,
      correctionData:{},
      correctionQuestionType:'',
      categoryId:'',
      categoryList:[],
      secondCategoryId:'',
      secondCategoryList:[],
      threeCategoryId:'',
      threeCategoryList:[],
      statusesObj:{},
      batchList:[],
      batchId:'',
      dataStatusObj:{},
      correctionTypes:{},
      messageModal:false,
      messageForm:{
        message:''
      },
      ruleMessage:{
        message: [
          { required: true, message: '请输入原因', trigger: 'blur' }
        ],
      },
      saveStatus:'',
      updataStatus:'',
      lowerShelf:false, //是否为疑难和下线
      allCategoryList:[],
      statusObj:{},
      levelsObj:{},
      sourceFroms:{},
      correctTypeList:[],
      data_correction_type:"",
      generate_by:"",
      generate_by_list:[
        {
          name:'全部',
          id:"0"
        },
        {
          name:'学员反馈',
          id:"1"
        },
        {
          name:'chatgpt',
          id:"2"
        },
      ]
    }
  },
  components:{
    Richtxt,
    EditData
    // DeleModal
  },
  created(){
    // this.getList();
    this.getCategoryList();
  },
  methods:{
    syncTit(){  //同步标题
      this.correctionInfo.name = this.correctionData.name;
    },
    syncOption(index){ //同步选项
      if(this.correctionInfo.options[index]){
        this.correctionInfo.options[index].name = this.correctionData.options[index].name;
      }else{
        this.correctionInfo.options.push({
          name:this.correctionData.options[index].name,
          id:'0',
          is_correct:'0'
        })
      }
      console.log(this.correctionInfo,'this.correctionInfo')
    },
    syncAnalysis(){ //同步解析
      this.correctionInfo.explain = this.correctionData.explain;
    },
    syncIsRight(){  //同步正确答案
      if(this.correctionData.options){
        let arr = this.correctionData.options.filter((item)=>{
          return item.is_correct == 1;
        });
        this.correctionInfo.options.forEach((item)=>{
          let index = arr.findIndex((sItem)=>{
            return sItem.id == item.id
          });
          if(index != -1){
            item.is_correct = 1;
          }else{
            item.is_correct = 0;
          }
        })
      }

    },
    syncAll(){  //同步所有
      this.correctionInfo.name = this.correctionData.name;
      this.correctionInfo.explain = this.correctionData.explain;
      if(this.correctionData.options){
        this.correctionData.options.forEach((item,index)=>{
          if(this.correctionInfo.options[index]){
            this.correctionInfo.options[index].name = item.name;
          }else{
            this.correctionInfo.options.push({
              name:item.name,
              id:'0',
              is_correct:'0'
            })
          }
        })
      }else{
        this.correctionInfo.options = [];
      }

      this.syncIsRight();
    },
    getCategoryList(){
      let data = {
        category_id:this.categoryId,
        category_second_id:this.secondCategoryId,
        site_id:-1,
      };
      //console.log('aaa');
      this.loading = true;
      this.api.dataset.categoryForm(data).then((res)=>{
        this.loading = false;

        //一级标签
        this.categoryList = [
          {
            name:this.$t('trainingcamp_train_all'),
            id:'-1'
          },
          ...res.categoryFirstList
        ];
        //二级标签
        this.secondCategoryList = [
          {
            name:this.$t('trainingcamp_train_all'),
            id:'-1'
          },
          ...res.categorySecondList
        ];

        this.threeCategoryList = [
          {
            name:this.$t('trainingcamp_train_all'),
            id:'-1'
          },
          ...res.categoryThirdList
        ]

        this.getList();
      }).catch((err)=>{
        this.loading = false;
      })
    },
    changeCategory(data,name){
      this[name] = data;
      if(name == 'categoryId'){
        this.secondCategoryId = '';
        this.threeCategoryId = '';
      }else if(name == 'secondCategoryId'){
        this.threeCategoryId = '';
      }

      this.getCategoryList();
    },
    refresh(){
      this.curIndex = 0;
      this.getList();
    },
    getList(){
      this.loading = true;
      let params = {
        page:this.page,
        pageSize:this.pageSize,
        status:this.status, //0待处理1已处理2忽略
        keyword:this.keyword,
        user_id:-1,
        site_id:-1,
        batch:this.batchId,
        data_correction_type:this.data_correction_type,
        generate_by:this.generate_by
      };
      if(this.threeCategoryId && this.threeCategoryId > -1){
        params.category_id = this.threeCategoryId;
      }else if(this.secondCategoryId && this.secondCategoryId > -1){
        params.category_id = this.secondCategoryId;
      }else if(this.categoryId){
        params.category_id = this.categoryId;
      }
      console.log(params.category_id)
      console.log(this.threeCategoryId,this.secondCategoryId,this.categoryId)
      this.api.dataset.datasetCorrectionList(params).then((res)=>{
        this.dataList = res.list;
        this.curIndex = 0;
        this.correctAnswer = '';
        // if(this.dataList.length){
        //   this.formateInfo();
        // }
        this.loading = false;
        this.remainingTotal = Number(res.count);
        this.tabList = [];
        this.typesObj = res.data_types;

        this.typesList = [];
        for(let name in res.data_types){
          this.typesList.push({
            name:res.data_types[name],
            value:name
          })
        }
        for(let name in res.statuses){
          this.tabList.push({
            name:res.statuses[name],
            id:name
          })
        }
        this.statusesObj = res.statuses;
        this.batchList = [
          {
            batch:this.$t('trainingcamp_train_all'),
            id:'-1'
          },
          ...res.batchList
        ];
        this.dataStatusObj = res.data_statuses;

        const arr=[];
        Object.keys(res.data_correction_types)?.forEach(key=>{
          arr.push({
            id:key,
            name:res.data_correction_types[key]
          });
        });
        this.correctTypeList=arr;

        this.getInfo();
        this.geCorrectiontInfo();
      }).catch((err)=>{
        this.loading = false;
      })
    },
    getInfo(){
      if(!this.dataList[this.curIndex]){
        this.correctionInfo = {};
        return;
      }
      let params = {
        id:this.dataList[this.curIndex].data_id
      };
      this.infoLoading = true;
      this.api.dataset.dataDetail(params).then((res)=>{
        this.infoLoading = false;
        this.correctionInfo = JSON.parse(JSON.stringify(res.info));

        this.correctionInfo.labelKeywordsObj = this.correctionInfo.labelKeywords;
        this.correctionInfo.labelKeywords = this.correctionInfo.labelKeywords.length ? this.correctionInfo.labelKeywords.map((item)=>{
          return item.label_id
        }) : [];
        this.correctionInfo.labelKeypointsObj = this.correctionInfo.labelKeypoints;
        this.correctionInfo.labelKeypoints = this.correctionInfo.labelKeypoints ? this.correctionInfo.labelKeypoints.map((item)=>{
          return item.label_id
        }) : [];

        this.$set(this.correctionInfo,'categoryIds',[]);
        for(let name in this.correctionInfo.categoryList){
          this.correctionInfo.categoryIds.push(name)
        }

        this.$set(this.correctionInfo,'infoCategoryList',[]);
        for(let name in this.correctionInfo.categoryList){
          let categoryItem = this.correctionInfo.categoryList[name].map((item)=>{
            return item.name;
          }).join('-');
          this.correctionInfo.infoCategoryList.push(categoryItem)
        }
        this.correctionInfo.infoCategoryList = this.correctionInfo.infoCategoryList.join(',');

        this.allCategoryList = [];
        for(let name in res.info.categoryList){
          let names = res.info.categoryList[name].map(sItem=>{
            return sItem.name+'(id:'+sItem.id+')'
          }).join('-');
          this.allCategoryList.push({
            name:names,
            value:name
          })
        }

        this.typesObj = res.types;
        this.levelsObj = res.levels;
        this.sourceFroms = res.source_froms;
        this.statusObj = res.statuses;


        this.questionType = this.typesObj[this.correctionInfo.type];
      }).catch((err)=>{
        this.infoLoading = false;
      });
    },
    geCorrectiontInfo(){
      console.log(this.dataList[this.curIndex])
      if(!this.dataList[this.curIndex]){
        console.log('44444')
        this.correctionData = {};
        return;
      }
      let params = {
        id:this.dataList[this.curIndex].id
      };
      this.correctionDataLoading = true;
      this.api.dataset.datasetCorrectionDetail(params).then((res)=>{
        this.correctionDataLoading = false;
        this.correctionData = JSON.parse(JSON.stringify(res.info));

        this.correctionData.created_at = util.timeFormatter(
            new Date(+this.correctionData.created_at * 1000),
            'yyyy-MM-dd hh:mm'
        )
        this.correctionQuestionType = this.typesObj[this.correctionData.type];
        this.correctionTypes = res.data_correction_types;
      }).catch((err)=>{
        this.correctionDataLoading = false;
      });
    },
    changeAnswerCard(index){
      this.curIndex = index;
      this.getInfo();
      this.geCorrectiontInfo();
      // this.formateInfo();
    },
    next(){ //下一题
      if(this.curIndex == this.dataList.length - 1){
        this.$Message.warning(this.$t('trainingcamp_train_last_test'));
        return;
      }
      this.curIndex = this.curIndex + 1;
      this.getInfo();
      this.geCorrectiontInfo();
      // this.formateInfo();
    },
    pre(){  //上一题
      if(this.curIndex == 0){
        this.$Message.warning(this.$t('trainingcamp_train_first_test'));
        return;
      }
      this.curIndex = this.curIndex - 1;
      this.getInfo();
      this.geCorrectiontInfo();
      // this.formateInfo();
    },
    save(fn){
      if(!this.correctionInfo.categoryIds.length){
        this.$Message.warning('请选择分类');
        return;
      }
      if(!this.correctionInfo.id){
        this.$Message.warning('请选择数据');
        return;
      }
      if(!this.correctionInfo.name){  //标题
        this.$Message.warning(this.$t('trainingcamp_train_warning_tit'));
        return;
      }

      if(this.correctionInfo.type == '1' || this.correctionInfo.type == '2' || this.correctionInfo.type == '3' || this.correctionInfo.type == '4') {
        if (!this.correctionInfo.options.length && !this.lowerShelf) { //选项
          this.$Message.warning(this.$t('trainingcamp_train_warning_add_option'));
          return;
        }
        for(let i=0;i<this.correctionInfo.options.length;i++){
          if(!this.correctionInfo.options[i].name){
            this.$Message.warning(this.$t('trainingcamp_train_warning_input_cont'));
            return;
            break;
          }
        }
      }
      if(this.correctionInfo.type == '1' || this.correctionInfo.type == '2' || this.correctionInfo.type == '3') {
        let len = this.correctionInfo.options.filter((item) => {
          return item.is_correct == '1';
        }).length

        if (!len && !this.lowerShelf) { //正确选项
          this.$Message.warning(this.$t('trainingcamp_train_warning_select'));
          return;
        }


        if (this.correctionInfo.type == '1' || this.correctionInfo.type == '3') {
          if (len > 1) {
            this.$Message.warning('只能选择一个正确答案');
            return;
          }
        }
      }
      if(!this.correctionInfo.labelKeywords.length){
        this.$Message.warning('请选择标签');
        return;
      }


      let params = {
        id:this.correctionInfo.id,
        name:this.correctionInfo.name,
        explain:this.correctionInfo.explain,
        options:JSON.stringify(this.correctionInfo.options),
        type:this.correctionInfo.type,
        level:this.correctionInfo.level,
        status:this.correctionInfo.status,
        source:this.correctionInfo.source,
        label_keyword_ids:this.correctionInfo.labelKeywords.join(','),
        label_keypoint_ids:this.correctionInfo.labelKeypoints.join(','),
        category_ids:this.correctionInfo.categoryIds.join('|'),
        site_id:'-1',
      };

      this.api.dataset.dataUpdate(params).then((res)=>{
        this.$Message.success({
          content: this.$t('trainingcamp_exam_feedback_save_success'),
          duration: 3,
        });
        if(fn){
          fn();
        }
      })
    },
    // submit(saveStatus,updataStatus,lowerShelf){ //保存并提交
    //   this.lowerShelf = lowerShelf || false;
    //   if(updataStatus == 2){  //无效
    //
    //     this.$set(this.dataList[this.curIndex],'status','1');
    //     this.feedbackUpdate(updataStatus)
    //     this.curIndex++;
    //     this.getInfo();
    //     this.geCorrectiontInfo();
    //   }else{
    //     this.save(saveStatus,()=>{
    //       this.$set(this.dataList[this.curIndex],'status','1');
    //       this.feedbackUpdate(1)
    //     });
    //   }
    // },
    // ignore(){ //忽略
    //   this.feedbackUpdate(2,()=>{
    //     this.$set(this.dataList[this.curIndex],'status','2');
    //     this.$Message.success(this.$t('trainingcamp_exam_feedback_save_success'));
    //     this.curIndex++;
    //     this.getInfo();
    //     this.geCorrectiontInfo();
    //   })
    // },
    feedbackUpdate(status,fn){
      let params = {
        id:this.dataList[this.curIndex].id,
        status:status,
        reason:this.messageForm.message
      };
      this.api.dataset.datasetCorrectionUpdate(params).then((res)=>{

        fn && fn();
      })
    },
    // saveCurData(curData,data,name){
    //   data[name] = curData;
    // },
    // changeCorrect(data){  //修改正确选项
    //   // console.log(this.questionType,'questionType')
    //   if(this.questionType == '单选' || this.questionType == '判断'){ //单选
    //     if(data.is_correct == '0'){
    //       this.correctionInfo.options.forEach((item)=>{
    //         item.is_correct = '0';
    //       });
    //       this.$nextTick(()=>{
    //         data.is_correct = '1';
    //       })
    //
    //       console.log(data)
    //     }else{
    //       this.$nextTick(()=>{
    //         data.is_correct = '0';
    //       })
    //     }
    //   }else{
    //     data.is_correct == '0'  ? data.is_correct = '1' : data.is_correct = '0';
    //   }
    //
    // },
    // addOption(){  //添加选项
    //   if(this.correctionInfo.options.length == 6){
    //     this.$Message.warning(this.$t('trainingcamp_train_option_max'));
    //     return;
    //   }
    //   this.correctionInfo.options.push({
    //     name:'',
    //     id:'0',
    //     is_correct:'0'
    //   })
    // },
    // deleOption(index){  //删除选项
    //   this.correctionInfo.options.splice(index, 1);
    // },
    changeTab(data){
      this.radio = '';
      this.checkbox = [];
      this.correctIndex = '';
      this.correctAnswer = '';
      this.curIndex = 0;
      this.status = data.id;
      this.page = 1;
      this.getList();
    },
    changePage(page){
      this.page = page;
      this.getList();
    },
    messageCancel(){
      this.messageModal = false;
    },
    messageConfirm(){
      this.$refs['batchOffForm'].validate((valid) => {
        if (valid) {
          // this.submit(this.saveStatus,this.updataStatus,true);
          // this.dataVerify();

          this.save(()=>{
            this.$set(this.dataList[this.curIndex],'status','1');
            this.feedbackUpdate(1,()=>{
              this.messageModal = false;
              console.log(this.messageModal,'this.messageModal')
              this.next();

            })
          });
        }
      })
    },
    // dataVerify(){
    //   let params = {
    //     id:this.correctionInfo.id,
    //     status:this.saveStatus,
    //     message:this.messageForm.message
    //   };
    //   this.api.dataset.datasetDataVerify(params).then((res)=>{
    //     this.$Message.success('操作成功');
    //
    //
    //     this.save(saveStatus,()=>{
    //       this.$set(this.dataList[this.curIndex],'status','1');
    //       this.feedbackUpdate(updataStatus)
    //     });
    //
    //
    //     this.curIndex++;
    //     this.getInfo();
    //     this.geCorrectiontInfo();
    //   })
    // },
    showMessage(saveStatus,updataStatus){
      // this.saveStatus = saveStatus;
      // this.updataStatus = updataStatus;
      this.messageModal = true;
      this.messageForm.message = '';
    },
    changeData(data){
      this.correctionInfo = JSON.parse(JSON.stringify(data));
    },
  }
}
</script>

<style scoped lang="scss">
.feedback-modify{
  padding: 20px;
  font-size: 14px;
  .top-info{
    margin-bottom: 20px;
    padding: 20px;
    border: 1px solid #dcdee2;
    background: #FFFFFF;
    flex-flow: column;

    .top-info-t{
      margin-bottom: 10px;
      display: flex;
      justify-content: flex-start;
      font-size: 18px;
      >div{
        margin-right: 30px;
      }
    }
    .top-info-b{
      margin-bottom: 10px;
      display: flex;
      justify-content: flex-start;

    }
  }
  .feedback-modify-main{
    display: flex;
    justify-content: space-between;
  }

  .left{
    flex: 1;
    margin-right: 20px;
    position: relative;

    >div{
      display: flex;
      justify-content: flex-start;
    }

    .left-top{
      flex: 3;

      padding:20px 20px 40px 20px;
      border: 1px solid #dcdee2;
      background-color: #FFFFFF;
      font-size: 16px;
      position: relative;

      .tit{

        height: 40px;
        line-height: 40px;
        font-size: 20px;
        font-weight: bold;
        >span:nth-child(2){
          margin-left: 20px;
          font-size: 16px;
          font-weight: normal;
        }
        >span:nth-child(3){
          margin-left: 5px;
          font-size: 12px;
          font-weight: normal;
        }
      }
      .name{
        margin:10px 0 ;
      }
      .cont{
        .option{
          margin-bottom: 10px;
        }
      }
      .answer-cont{
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        >span:nth-child(1){
          width: 50px;
        }
        >span:nth-child(2){
          flex: 1;
          width: 0;
          word-break: break-all;
          word-wrap: break-word;
          white-space: pre-wrap;
        }
      }

    }
    .answer{
      margin-top: 40px;
      margin-bottom: 10px;

      .blue{
        color: #2d8cf0;
      }
      .green{
        color: #19be6b;
      }
      .red{
        color: #ed4014;
      }
    }
    .left-right{
      flex: 3;
      margin-right: 20px;
      padding:20px 20px 40px 20px;
      border: 1px solid #dcdee2;
      background-color: #FFFFFF;
      font-size: 16px;
      position: relative;
      //height: 644px;

    }
  }
  .left-right-top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 20px
  }
  .right{
    width: 400px;
    .tab{
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 40px;
      line-height: 40px;
      font-size: 16px;

      .tab-list{
        border: 1px solid #dcdee2;
        border-radius: 4px;
        overflow: hidden;
        height: 100%;

        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #FFFFFF;
        width: 100%;
        >div{
          flex: 1;
          text-align: center;
          height: 100%;
          //padding: 0 20px;
          border-right: 1px solid #dcdee2;
        }
        >div:last-child{
          border-right: none;
        }
        >div.active{
          background-color: #2d8cf0;
          color: #FFFFFF;
        }
      }

    }
    .search{
      margin-bottom: 20px;
    }
    .catrgory-search{
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      >div{
        flex: 1;
        margin-right: 10px;
        >p{
          margin-bottom: 5px;
        }
      }
      >div:last-child{
        margin-right: 0;
      }
    }
    .right-top{
      border: 1px solid #dcdee2;
      background-color: #FFFFFF;


      .tit{
        padding: 0 20px;
        height: 40px;
        line-height: 40px;
        font-size: 20px;
        font-weight: bold;
        border-bottom: 1px solid #dcdee2;
        display: flex;
        justify-content: space-between;
        align-items: center;
        >div{
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .count{
            margin-left: 20px;
            font-size: 14px;
            font-weight: normal;
            >span{
              padding: 0 5px;
              color:#2d8cf0;
            }
          }
        }
      }
      .again{
        white-space:normal
      }
      .answer-card{
        margin: 20px 10px;
        height: 450px;
        overflow-y: auto;
        position: relative;
        .list{
          display: flex;
          justify-content: flex-start;
          flex-flow: wrap;
          >div{
            width: 50px;
            height: 30px;
            border: 1px solid #dcdee2;
            text-align: center;
            line-height: 30px;
            border-radius: 4px;
            margin: 0 10px 10px 10px;
            cursor: pointer;
          }

          >div.error{
            background-color: #ec808d;
            color:#FFFFFF;
          }
          >div.corret{
            background-color: #19be6b;
            color:#FFFFFF;
          }
          >div.active{
            background-color: #2d8cf0;
            //border: 1px solid #2d8cf0;
            color:#FFFFFF;
          }
          >div.error.active{
            background-color:red;
            border: none;
          }
          >div.corret.active{
            background-color: green;
            border: none;
          }
        }
        .page{
          margin-top: 15px;
          text-align: right;
        }
      }
      .num{
        margin-top: 20px;
        margin-bottom: 10px;
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
        span{
          padding-left:5px;
          padding-right: 2px;
          font-weight: bold;
        }

        .blue{
          color:#19be6b;
        }
        .red{
          color:#ed4014;
        }
      }
    }
  }
  .option{
    font-size: 16px;
    //white-space: -moz-pre-wrap;
    white-space:normal;
    height: auto;
  }
  .checkoutOption{
    display: block;
    font-size: 16px;
    margin-bottom: 10px;
    white-space:normal;
    height: auto;
  }
}
.bjColor{
  background-color: #dee6de;
}
.nrBjColor{
  background-color: #f0faef !important;
}
.big-font-size{
  font-size: 18px !important;
}
.little-font-size{
  font-size: 14px!important;
}
.middle-font-size{
  font-size: 16px!important;
}
.correction,
.left-right{  //纠错
  //height: 500px;
  overflow-y: auto;
  font-size: 14px;
  .correction-name,
  .tit{
    font-size: 20px;
    margin-bottom: 20px;
  }
  .correction-type{
    margin-bottom: 20px;
  }
  .correction-tit,
  .correction-option-item,
  .correction-analysis,
  .feedback-item{
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    .correction-left,
    .feedback-item-tit{
      width: 70px;
      text-align: right;
      font-size: 14px;
      overflow: hidden;
      font-weight: bold;


      .correction-correct{
        margin: 5px 0;
        border: 1px solid #dcdee2;
        border-radius: 4px;
        padding: 5px 0;
        text-align: center;
        cursor: pointer;
      }
      .correction-dele{
        float: right;
        width: 50px;
        color:#FFFFFF;
        border-radius: 4px;
        padding: 5px 0;
        text-align: center;
        background-color: #ed4014;
        cursor: pointer;
      }
      .active{
        border: 1px solid #19be6b;
        background-color: #19be6b;
        color:#FFFFFF;
      }
    }
    .correction-right,
    .feedback-item-cont{
      margin-left: 20px;
      flex: 1;
      //border: 1px solid #dcdee2;
    }
  }
  .feedback-item{
    display: block;
  }
  .feedback-item-tit{
    text-align: left !important;
    margin-bottom: 10px;
  }
  .feedback-item-cont{
    margin-left: 0!important;
  }
  .correction-analysis{
    margin-top: 20px;
  }
  .catrgory-search{
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.correction-top{
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  .correction-top-l{
    display: flex;
    justify-content: flex-start;
  }
}
.correct-tit{
  width: 70px;
  text-align: right;
  font-weight: bold;
  margin-right: 20px;
  display: inline-block;
}
.bot{
  position: fixed;
  margin-top: 20px;
  padding: 20px;
  right: 440px;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 999;
  background-color: #FFFFFF;
  .btn{
    button{
      margin-right: 10px;
    }
  }

  .op{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    >div{
      margin-left: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;

      >img{
        margin-right: 5px;
        width: 20px;
        height: 20px;
      }
    }

  }
}
</style>
<style>
.data-verify .ivu-checkbox-disabled+span{
  color:#515a6e !important;
}
</style>
